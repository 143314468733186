import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { Form, FormInstance, Image, Input, Modal, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { storageItemApi, storageItemNotesApi, storageProcessApi } from '../../../../apis';
import {
  CreateStorageItemNoteDto,
  StorageItem,
  StorageItemNote,
  TakePhotoRequest,
  UpdateDisposalRequestDto,
  UploadRealVolumeDto,
  UploadStorageVolumeDto,
} from '../../../../apis/client-axios';
import SvgCloseModalIcon from '../../../../components/@svg/SvgCloseModalIcon';
import ButtonAgree from '../../../../components/buttonCustom/ButtonAgree';
import ButtonCancel from '../../../../components/buttonCustom/ButtonCancel';
import { CustomCommonButton } from '../../../../components/buttons/common/CustomButton';
import FormWrap from '../../../../components/FormWrap';
import CustomInputArea from '../../../../components/input/CustomTextArea';
import VideoModal from '../../../../components/modals/videoModal';
import NotificationSuccess from '../../../../components/notifications/NotificationSuccess';
import ViewStatus from '../../../../components/ViewStatus';
import { RootState } from '../../../../store';
import {
  AdminRole,
  QUERY_KEY,
  STATUS_ITEM_MANAGEMENT,
  TAKE_PHOTO_REQUEST_STATUS,
  TIME_FORMAT,
} from '../../../../util/constants';
import { Helper, renderStatus } from '../../../../util/helper';
import { printSKU } from '../../../../util/print';
import { ChatItem } from '../chatItem';
import TakePhotoModal from '../modals/takePhotoModal';
import UploadMedia from '../UploadFiles/uploadMedia';
import './index.scss';
import { ADMIN_ROUTE_PATH } from '../../../../constants/router';
import NotificationError from '../../../../components/notifications/NotificationError';
import { SvgDolarIcon } from '../../../../components/@svg/SvgDolarIcon';
import _, { forEach } from 'lodash';
import SvgWarningIcon from '../../../../components/@svg/SvgWarningIcon';
import TextArea from 'antd/es/input/TextArea';

const ItemDetail = () => {
  const { id } = useParams();
  const { authUser } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const intl = useIntl();
  const location = useLocation();
  const [formUploadByStatus] = Form.useForm<FormInstance>();
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);
  const [activeModalCancel, setActiveModalCancel] = useState(false);
  const [activeAddPhoto, setActiveAddPhoto] = useState<{ isShow: boolean; takePhotoRequest: TakePhotoRequest }>({
    isShow: false,
    takePhotoRequest: null,
  });
  const [statusUploadFiles, setStatusUploadFiles] = useState<{ isShow: boolean; takePhotoRequest: TakePhotoRequest }>({
    isShow: false,
    takePhotoRequest: null,
  });
  const [activeRegisterNote, setActiveRegisterNote] = useState(false);
  const [activeNoteItem, setActiveNoteItem] = useState(false);
  const [lstNote, setLstNote] = useState<StorageItemNote[]>([]);
  const [noteDetail, setNoteDetail] = useState<StorageItemNote>();
  const [itemDetailRealVol, setItemDetailRealVol] = useState<UploadRealVolumeDto>({
    realLength: undefined,
    realHeight: undefined,
    realWeight: undefined,
    realWidth: undefined,
    storageItemId: 0,
    staffComment: undefined,
  });
  const [itemDetailStorageVol, setItemDetailStorageVol] = useState<UploadStorageVolumeDto>({
    storageLength: undefined,
    storageHeight: undefined,
    storageWeight: undefined,
    storageWidth: undefined,
    storageItemId: 0,
    staffComment: undefined,
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isOpenCheck, setIsOpenCheck] = useState(false);
  const [itemDetail, setItemDetail] = useState<StorageItem>(undefined);
  const [visibleSender, setVisibleSender] = useState(false);
  const [previewSender, setPreviewSender] = useState<{ source: string; index: number } | undefined>(undefined);
  const [form] = Form.useForm<FormInstance>();
  const [formUpdateItem] = Form.useForm<FormInstance>();
  const statusItem: string = renderStatus(itemDetail);
  const ctrlSize = !itemDetailStorageVol?.staffComment;
  const {
    data: listNote,
    isLoading,
    refetch,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [QUERY_KEY.LIST_NOTE],
    queryFn: ({ pageParam }) => storageItemNotesApi.storageItemNoteControllerFindAllByItemStorageId(id as string, 1),
    initialPageParam: 1,
    enabled: !!id,
    getNextPageParam: (_, __, page) => page + 1,
  });

  const { data: itemDetailRs, refetch: onRefetchItems } = useQuery({
    queryKey: [QUERY_KEY.ITEM_DETAIL, id],
    queryFn: () => storageItemApi.storageItemControllerGetOneItemManagementAdmin(id),
    enabled: true,
    staleTime: 1000,
  });

  const createNote = useMutation({
    mutationFn: (dto: CreateStorageItemNoteDto) =>
      storageItemNotesApi.storageItemNoteControllerCreate(dto).then((res) => res?.data),
    onSuccess: () => {
      NotificationSuccess({ content: intl.formatMessage({ id: 'common.createSuccess' }) });
      handleCloseRegisterNote();
      refetch();
    },
    onError: (error) => {},
  });

  const uploadRealVolume = useMutation({
    mutationFn: (itemCheckDto: UploadRealVolumeDto) =>
      storageProcessApi.storageProcessControllerUploadRealVolume(itemCheckDto),
    onSuccess: ({ data }) => {
      uploadStorageVolume.mutate({ ...itemDetailStorageVol, storageItemId: itemDetail?.id });
      onRefetchItems();
    },
    onError: (error: any) => {
      const msgError: string = error?.response?.data?.message ?? '';
      if (msgError.includes('Hard:')) {
        NotificationError({ content: msgError });
      }
      setIsLoadingUpload(false);
    },
  });

  const uploadStorageVolume = useMutation({
    mutationFn: (itemCheckDto: UploadStorageVolumeDto) =>
      storageProcessApi.storageProcessControllerUploadStorageVolume(itemCheckDto),
    onSuccess: ({ data }) => {
      NotificationSuccess({ content: intl.formatMessage({ id: 'common.updateSuccess' }) });
      onRefetchItems();
      setIsLoadingUpload(false);
    },
    onError: (error: any) => {
      setIsLoadingUpload(false);
    },
  });

  useEffect(() => {
    const lst: StorageItemNote[] = [];
    listNote?.pages?.forEach((page) => {
      page?.data?.content?.forEach((staff) => {
        lst.push(staff);
      });
    });
    // console.log('StorageItemNote', lst);
    setLstNote(lst ?? []);
  }, [listNote]);

  useEffect(() => {
    if (itemDetailRs && itemDetailRs.data) {
      setItemDetail(itemDetailRs.data);
      setItemDetailStorageVol({
        ...itemDetailStorageVol,
        staffComment: itemDetailRs.data.staffComment ?? '',
      });
      setItemDetailRealVol({
        ...itemDetailRealVol,
        staffComment: itemDetailRs.data.staffComment ?? '',
      });
    }
  }, [itemDetailRs]);

  const handleOpenModalCancel = () => {
    setActiveModalCancel(true);
  };
  const handleCloseModalCancel = () => {
    setActiveModalCancel(false);
  };
  const handleOpenAddPhoto = (rq: TakePhotoRequest) => {
    setActiveAddPhoto({ isShow: true, takePhotoRequest: rq });
  };
  const handleCloseAddPhoto = () => {
    setActiveAddPhoto({ isShow: false, takePhotoRequest: null });
  };
  const handleOpenRegisterNote = () => {
    setActiveRegisterNote(true);
  };
  const handleCloseRegisterNote = () => {
    setActiveRegisterNote(false);
    form.resetFields();
  };
  const handleOpenNoteItem = (note: StorageItemNote) => {
    setActiveNoteItem(true);
    setNoteDetail(note);
  };
  const handleCloseNoteItem = () => {
    setActiveNoteItem(false);
    setNoteDetail(undefined);
  };

  const handlePrintSKU = () => {
    if (itemDetail?.internalSku) {
      printSKU(itemDetail?.internalSku, intl.formatMessage({ id: 'error.500' }));
    }
  };

  const handleRegisterNote = () => {
    const noteContent = form.getFieldValue('message')?.toString()?.trim();
    form.validateFields();
    // console.log(noteContent);
    if (!noteContent) {
      NotificationError({
        content: intl.formatMessage({ id: 'chat-item.send' }),
      });
      return;
    }
    if (id) {
      createNote.mutateAsync({ storageItemId: +id, content: noteContent });
    }
  };

  const handleBackButton = () => {
    if (location.pathname.includes(ADMIN_ROUTE_PATH.REQUEST_MANAGEMENT)) {
      navigate(ADMIN_ROUTE_PATH.REQUEST_MANAGEMENT);
    } else {
      navigate(ADMIN_ROUTE_PATH.ITEM_MANAGEMENT);
    }
  };

  const renderListNote = useMemo(() => {
    return lstNote.map((note, index) => (
      <div className="item-detail_feedback_content_item" key={index}>
        <div className="item-detail_feedback_content_item_img">
          <img src={Helper.getSourceFile(note.staff.avatar?.source || note.staff.avatar?.preview)} alt="" />
        </div>
        <div className="item-detail_feedback_content_item_text">
          <h6>{note.staff.name}</h6>
          <span>{moment(note.createdAt).format(TIME_FORMAT.MESSAGE_TIME)}</span>
          <p className="text-2-line text-pre-line">{note.content}</p>
        </div>
        <button onClick={() => handleOpenNoteItem(note)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <path
              d="M14.2353 8.94112V14.2352C14.2353 14.7033 14.0494 15.1521 13.7184 15.4831C13.3875 15.814 12.9386 15.9999 12.4706 15.9999H2.76471C2.29668 15.9999 1.84782 15.814 1.51687 15.4831C1.18592 15.1521 1 14.7033 1 14.2352V4.52935C1 4.06133 1.18592 3.61247 1.51687 3.28152C1.84782 2.95057 2.29668 2.76465 2.76471 2.76465H8.05882"
              stroke="#514B4D"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M9.82373 1H15.1178V6.29412" stroke="#514B4D" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.41162 10.7059L15.1175 1" stroke="#514B4D" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      </div>
    ));
  }, [lstNote]);

  const renderTextStatusPhotoRequest = (status: string) => {
    let txtStatus = '';
    if (status == TAKE_PHOTO_REQUEST_STATUS.REQUESTED) {
      txtStatus = '写真撮影待ち';
    } else if (status == TAKE_PHOTO_REQUEST_STATUS.TAKE_BY_OPERATION) {
      txtStatus = '写真アップロード待ち';
    } else if (status == TAKE_PHOTO_REQUEST_STATUS.UPLOAD_BY_STAFF) {
      txtStatus = '対応済';
    }
    return txtStatus;
  };

  const renderTakePhotoRequest = useMemo(() => {
    return itemDetail?.takePhotoRequests?.map((rq) => {
      return (
        <tr>
          <td>
            <p>{moment(rq.createdAt).format(TIME_FORMAT.DATE('/'))}</p>
          </td>
          <td>
            <p>{rq.photoQuantity}</p>
          </td>
          <td>
            <p className="text-1-line">{rq.userMessage}</p>
          </td>
          <td className="item-detail_content_photo_table_wrap_status">
            <p
              className={`item-detail_content_photo_table_wrap_status_${rq.status}`}
              onClick={() => {
                handleOpenAddPhoto(rq);
              }}
            >
              {renderTextStatusPhotoRequest(rq.status)}
            </p>
          </td>
        </tr>
      );
    });
  }, [itemDetail]);

  const handleViewButton = (status: string) => {
    switch (status) {
      case STATUS_ITEM_MANAGEMENT.IN_TRANSIT:
      case STATUS_ITEM_MANAGEMENT.DELIVERED:
        return 'inspectionComplete';
      case STATUS_ITEM_MANAGEMENT.WAITING_UPLOAD_IMAGE_VIDEO:
        return 'registration';
      case STATUS_ITEM_MANAGEMENT.NEW_DISPOSAL_REQUEST:
        return 'disposalAmountRegistration';
      case STATUS_ITEM_MANAGEMENT.PRICE_INPUTED:
        return 'disposalCompleted';
      default:
        return '';
    }
  };
  const submitUpload = () => {
    formUploadByStatus.submit();
  };
  const submitSize = () => {
    setIsLoadingUpload(true);
    setIsOpenCheck(false);
    uploadRealVolume.mutate({ ...itemDetailRealVol, storageItemId: itemDetail.id });
  };
  const confirmDisposal = () => {
    completedDisposalMutation.mutate({ disposalRequestId: itemDetail?.disposalRequest?.id });
  };
  const handleActionButton = (status: string) => {
    switch (status) {
      case STATUS_ITEM_MANAGEMENT.IN_TRANSIT:
      case STATUS_ITEM_MANAGEMENT.DELIVERED:
        return handleCheckPercentDiff();
      case STATUS_ITEM_MANAGEMENT.WAITING_UPLOAD_IMAGE_VIDEO:
        return submitUpload();
      case STATUS_ITEM_MANAGEMENT.NEW_DISPOSAL_REQUEST:
        return setIsOpen(true);
      case STATUS_ITEM_MANAGEMENT.PRICE_INPUTED:
        return setIsOpenConfirm(true);
      default:
        return undefined;
    }
  };

  const renderStatusItem = useMemo(() => {
    return <ViewStatus status={statusItem || ''} />;
  }, [itemDetail]);

  useEffect(() => {
    if (itemDetail?.id) {
      setItemDetailStorageVol({
        storageHeight: itemDetail.storageHeight,
        storageWeight: itemDetail.storageWeight,
        storageWidth: itemDetail.storageWidth,
        storageLength: itemDetail.storageLength,
        storageItemId: itemDetail.id,
        staffComment: itemDetail.staffComment,
      });
      setItemDetailRealVol({
        realHeight: itemDetail.storageRequest.realHeight,
        realWeight: itemDetail.storageRequest.realWeight,
        realWidth: itemDetail.storageRequest.realWidth,
        realLength: itemDetail.storageRequest.realLength,
        storageItemId: itemDetail.id,
        staffComment: itemDetail.staffComment,
      });
    }
  }, [itemDetail]);

  const renderListPhoto = useMemo(() => {
    const arr = _.cloneDeep(itemDetail?.photos);
    if (itemDetail?.takePhotoRequests?.length > 0) {
      itemDetail?.takePhotoRequests.map((rq) => {
        arr.push(...rq.photos);
      });
    }
    return arr?.map((item, index) => {
      return (
        <div className="item-detail_content_photo_list_item">
          <Image
            key={index}
            onClick={() => {
              setVisibleSender(true);
              setPreviewSender({
                source: item?.source,
                index: +index + 1,
              });
            }}
            width={84}
            height={84}
            className="rounded"
            src={Helper.getSourceFile(item?.source)}
          />
          {/* cms k có download */}
          {/* <button
            className="item-detail_content_photo_list_item_download btn-download-single"
            onClick={() => {
              saveAs(Helper.getSourceFile(item?.source), item.name);
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                d="M14.0002 9.33331C13.8234 9.33331 13.6538 9.40355 13.5288 9.52857C13.4037 9.6536 13.3335 9.82317 13.3335 9.99998V12.6666C13.3335 12.8435 13.2633 13.013 13.1382 13.1381C13.0132 13.2631 12.8436 13.3333 12.6668 13.3333H3.3335C3.15668 13.3333 2.98712 13.2631 2.86209 13.1381C2.73707 13.013 2.66683 12.8435 2.66683 12.6666V9.99998C2.66683 9.82317 2.59659 9.6536 2.47157 9.52857C2.34654 9.40355 2.17697 9.33331 2.00016 9.33331C1.82335 9.33331 1.65378 9.40355 1.52876 9.52857C1.40373 9.6536 1.3335 9.82317 1.3335 9.99998V12.6666C1.3335 13.1971 1.54421 13.7058 1.91928 14.0809C2.29436 14.4559 2.80306 14.6666 3.3335 14.6666H12.6668C13.1973 14.6666 13.706 14.4559 14.081 14.0809C14.4561 13.7058 14.6668 13.1971 14.6668 12.6666V9.99998C14.6668 9.82317 14.5966 9.6536 14.4716 9.52857C14.3465 9.40355 14.177 9.33331 14.0002 9.33331ZM7.52683 10.4733C7.59023 10.534 7.66499 10.5816 7.74683 10.6133C7.82663 10.6486 7.91292 10.6668 8.00016 10.6668C8.08741 10.6668 8.1737 10.6486 8.2535 10.6133C8.33533 10.5816 8.41009 10.534 8.4735 10.4733L11.1402 7.80665C11.2657 7.68111 11.3362 7.51085 11.3362 7.33331C11.3362 7.15578 11.2657 6.98552 11.1402 6.85998C11.0146 6.73444 10.8444 6.66392 10.6668 6.66392C10.4893 6.66392 10.319 6.73444 10.1935 6.85998L8.66683 8.39331V1.99998C8.66683 1.82317 8.59659 1.6536 8.47157 1.52858C8.34654 1.40355 8.17697 1.33331 8.00016 1.33331C7.82335 1.33331 7.65378 1.40355 7.52876 1.52858C7.40373 1.6536 7.3335 1.82317 7.3335 1.99998V8.39331L5.80683 6.85998C5.74467 6.79782 5.67088 6.74851 5.58966 6.71487C5.50845 6.68123 5.4214 6.66392 5.3335 6.66392C5.24559 6.66392 5.15854 6.68123 5.07733 6.71487C4.99611 6.74851 4.92232 6.79782 4.86016 6.85998C4.798 6.92214 4.7487 6.99593 4.71506 7.07715C4.68142 7.15836 4.6641 7.24541 4.6641 7.33331C4.6641 7.42122 4.68142 7.50826 4.71506 7.58948C4.7487 7.67069 4.798 7.74449 4.86016 7.80665L7.52683 10.4733Z"
                fill="#514B4D"
              />
            </svg>
          </button> */}
        </div>
      );
    });
  }, [itemDetail, visibleSender]);
  const renderListVideo: React.ReactNode = useMemo(() => {
    if (!itemDetail?.videos.length) {
      return <></>;
    }
    return itemDetail?.videos?.map((item, index) => {
      return (
        <div className="item-detail_content_video_list_item">
          <VideoModal
            privew={<img src="/assets/icons/item-detail-demo.png" alt="" />}
            url={Helper.getSourceFile(item?.source)}
          />
          {/* cms k có download */}
          {/* <button
            className="item-detail_content_video_list_item_download btn-download-single"
            onClick={() => {
              saveAs(Helper.getSourceFile(item?.source), item.name);
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                d="M14.0002 9.33331C13.8234 9.33331 13.6538 9.40355 13.5288 9.52857C13.4037 9.6536 13.3335 9.82317 13.3335 9.99998V12.6666C13.3335 12.8435 13.2633 13.013 13.1382 13.1381C13.0132 13.2631 12.8436 13.3333 12.6668 13.3333H3.3335C3.15668 13.3333 2.98712 13.2631 2.86209 13.1381C2.73707 13.013 2.66683 12.8435 2.66683 12.6666V9.99998C2.66683 9.82317 2.59659 9.6536 2.47157 9.52857C2.34654 9.40355 2.17697 9.33331 2.00016 9.33331C1.82335 9.33331 1.65378 9.40355 1.52876 9.52857C1.40373 9.6536 1.3335 9.82317 1.3335 9.99998V12.6666C1.3335 13.1971 1.54421 13.7058 1.91928 14.0809C2.29436 14.4559 2.80306 14.6666 3.3335 14.6666H12.6668C13.1973 14.6666 13.706 14.4559 14.081 14.0809C14.4561 13.7058 14.6668 13.1971 14.6668 12.6666V9.99998C14.6668 9.82317 14.5966 9.6536 14.4716 9.52857C14.3465 9.40355 14.177 9.33331 14.0002 9.33331ZM7.52683 10.4733C7.59023 10.534 7.66499 10.5816 7.74683 10.6133C7.82663 10.6486 7.91292 10.6668 8.00016 10.6668C8.08741 10.6668 8.1737 10.6486 8.2535 10.6133C8.33533 10.5816 8.41009 10.534 8.4735 10.4733L11.1402 7.80665C11.2657 7.68111 11.3362 7.51085 11.3362 7.33331C11.3362 7.15578 11.2657 6.98552 11.1402 6.85998C11.0146 6.73444 10.8444 6.66392 10.6668 6.66392C10.4893 6.66392 10.319 6.73444 10.1935 6.85998L8.66683 8.39331V1.99998C8.66683 1.82317 8.59659 1.6536 8.47157 1.52858C8.34654 1.40355 8.17697 1.33331 8.00016 1.33331C7.82335 1.33331 7.65378 1.40355 7.52876 1.52858C7.40373 1.6536 7.3335 1.82317 7.3335 1.99998V8.39331L5.80683 6.85998C5.74467 6.79782 5.67088 6.74851 5.58966 6.71487C5.50845 6.68123 5.4214 6.66392 5.3335 6.66392C5.24559 6.66392 5.15854 6.68123 5.07733 6.71487C4.99611 6.74851 4.92232 6.79782 4.86016 6.85998C4.798 6.92214 4.7487 6.99593 4.71506 7.07715C4.68142 7.15836 4.6641 7.24541 4.6641 7.33331C4.6641 7.42122 4.68142 7.50826 4.71506 7.58948C4.7487 7.67069 4.798 7.74449 4.86016 7.80665L7.52683 10.4733Z"
                fill="#514B4D"
              />
            </svg>
          </button> */}
        </div>
      );
    });
  }, [itemDetail]);

  const isDisableInput = useMemo(() => {
    if ([STATUS_ITEM_MANAGEMENT.IN_TRANSIT, STATUS_ITEM_MANAGEMENT.DELIVERED].indexOf(statusItem) >= 0) {
      return false;
    } else {
      return true;
    }
  }, [itemDetail]);

  const updatePriceMutation = useMutation({
    mutationFn: (dto: UpdateDisposalRequestDto) => storageProcessApi.storageProcessControllerUpdateDisposalPrice(dto),
    onSuccess: ({ data }) => {
      NotificationSuccess({ content: intl.formatMessage({ id: 'common.updateSuccess' }) });
      setIsOpen(false);
      onRefetchItems();
    },
    onError: (error: any) => {},
  });

  const completedDisposalMutation = useMutation({
    mutationFn: (dto: UpdateDisposalRequestDto) => storageProcessApi.storageProcessControllerDisposalItem(dto),
    onSuccess: ({ data }) => {
      NotificationSuccess({ content: intl.formatMessage({ id: 'common.updateSuccess' }) });
      setIsOpenConfirm(false);
      onRefetchItems();
    },
    onError: (error: any) => {},
  });
  const onFinishFailedUpdatePrice = () => {
    formUpdateItem.validateFields();
    NotificationError({
      content: `${intl.formatMessage({ id: 'item.detail.disposalAmount' })}を入力`,
    });
  };
  const onFinishUpdatePrice = (value: UpdateDisposalRequestDto) => {
    if (value.price) {
      const price = value.price ? +value.price : 0;
      updatePriceMutation.mutate({
        disposalRequestId: itemDetail.disposalRequest?.id,
        price,
      });
    }
  };

  const handleShowNotiError = () => {
    if (!itemDetailStorageVol.storageLength) {
      NotificationError({ content: `${intl.formatMessage({ id: 'item.detail.length' })}を入力` });
    }
    if (!itemDetailStorageVol.storageWidth) {
      NotificationError({ content: `${intl.formatMessage({ id: 'item.detail.width' })}を入力` });
    }
    if (!itemDetailStorageVol.storageHeight) {
      NotificationError({ content: `${intl.formatMessage({ id: 'item.detail.height' })}を入力` });
    }
    if (!itemDetailStorageVol.storageWeight) {
      NotificationError({ content: `${intl.formatMessage({ id: 'item.detail.storageWeight' })}を入力` });
    }
  };

  const handleShowNotiRealError = () => {
    if (!itemDetailRealVol.realLength) {
      NotificationError({ content: `${intl.formatMessage({ id: 'item.detail.length' })}を入力` });
    }
    if (!itemDetailRealVol.realWidth) {
      NotificationError({ content: `${intl.formatMessage({ id: 'item.detail.width' })}を入力` });
    }
    if (!itemDetailRealVol.realHeight) {
      NotificationError({ content: `${intl.formatMessage({ id: 'item.detail.height' })}を入力` });
    }
    if (!itemDetailRealVol.realWeight) {
      NotificationError({ content: `${intl.formatMessage({ id: 'item.detail.storageWeight' })}を入力` });
    }
  };

  const handleCheckPercentDiff = () => {
    handleShowNotiError();
    let check: number = 0;
    const listRealData: number[] = [
      itemDetailRealVol.realLength,
      itemDetailRealVol.realWidth,
      itemDetailRealVol.realHeight,
      itemDetailRealVol.realWeight,
    ];
    listRealData.forEach((item) => {
      if (item > 0) {
        check++;
      }
    });
    if (
      !itemDetailStorageVol.storageLength ||
      !itemDetailStorageVol.storageWidth ||
      !itemDetailStorageVol.storageHeight ||
      !itemDetailStorageVol.storageWeight
    ) {
      return;
    } else if (
      itemDetailRealVol.realLength &&
      itemDetailRealVol.realWidth &&
      itemDetailRealVol.realHeight &&
      itemDetailRealVol.realWeight
    ) {
      const reportLength: number = itemDetail?.storageRequest?.reportLength ?? 0;
      const reportHeight: number = itemDetail?.storageRequest?.reportHeight ?? 0;
      const reportWidth: number = itemDetail?.storageRequest?.reportWidth ?? 0;
      const reportWeight: number = itemDetail?.storageRequest?.reportWeight ?? 0;
      const reportCapacity: number = (reportLength * reportHeight * reportWidth) / 5000;
      const realLength: number = itemDetailRealVol.realLength ?? 0;
      const realHeight: number = itemDetailRealVol.realHeight ?? 0;
      const realWidth: number = itemDetailRealVol.realWidth ?? 0;
      const realWeight: number = itemDetailRealVol.realWeight ?? 0;
      const realCapacity: number = (realLength * realHeight * realWidth) / 5000;

      const percentDifference: number = Number((((realCapacity - reportCapacity) / reportCapacity) * 100).toFixed());
      const percentDifferenceWeight: number = Number((((realWeight - reportWeight) / reportWeight) * 100).toFixed());
      if (
        percentDifference >= 20 ||
        percentDifference <= -20 ||
        percentDifferenceWeight >= 20 ||
        percentDifferenceWeight <= -20
      ) {
        setIsOpenCheck(true);
      } else {
        submitSize();
      }
    } else if (check > 0 && check < 4) {
      handleShowNotiRealError();
    } else {
      submitSize();
    }
  };
  return (
    <Spin size={'large'} spinning={isLoadingUpload}>
      <div className="item-detail">
        <div className="item-detail_back" onClick={handleBackButton}>
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
            <path
              d="M1.40118 6.3009H15.4013C15.587 6.3009 15.765 6.37465 15.8963 6.50593C16.0276 6.63721 16.1013 6.81526 16.1013 7.00091C16.1013 7.18656 16.0276 7.36461 15.8963 7.49589C15.765 7.62717 15.587 7.70092 15.4013 7.70092H1.40118C1.21552 7.70092 1.03748 7.62717 0.906199 7.49589C0.774922 7.36461 0.701172 7.18656 0.701172 7.00091C0.701172 6.81526 0.774922 6.63721 0.906199 6.50593C1.03748 6.37465 1.21552 6.3009 1.40118 6.3009Z"
              fill="#514B4D"
            />
            <path
              d="M1.69107 7.00095L7.49692 12.8054C7.62836 12.9368 7.7022 13.1151 7.7022 13.301C7.7022 13.4869 7.62836 13.6652 7.49692 13.7966C7.36548 13.9281 7.1872 14.0019 7.00131 14.0019C6.81543 14.0019 6.63715 13.9281 6.50571 13.7966L0.205653 7.49655C0.140464 7.43153 0.0887436 7.35428 0.0534543 7.26924C0.018165 7.18419 0 7.09302 0 7.00095C0 6.90887 0.018165 6.8177 0.0534543 6.73266C0.0887436 6.64761 0.140464 6.57037 0.205653 6.50534L6.50571 0.205286C6.63715 0.0738434 6.81543 0 7.00131 0C7.1872 0 7.36548 0.0738434 7.49692 0.205286C7.62836 0.336728 7.7022 0.515003 7.7022 0.700891C7.7022 0.886778 7.62836 1.06505 7.49692 1.1965L1.69107 7.00095Z"
              fill="#514B4D"
            />
          </svg>
        </div>
        <div className="item-detail_container">
          <div className="item-detail_content">
            <div className="item-detail_content_name-info">
              <h6>{intl.formatMessage({ id: 'item.detail.title' })}</h6>
              {authUser?.role != AdminRole.OPERATION && (
                <div className="item-detail_content_name-info_wrap">
                  <p>
                    {intl.formatMessage({ id: 'item.detail.sellerName' })}: {itemDetail?.storageRequest?.user?.name}
                  </p>
                  <p>
                    {intl.formatMessage({ id: 'item.detail.sellerId' })}: {itemDetail?.storageRequest?.user?.userCode}
                  </p>
                </div>
              )}
            </div>
            <div className={`item-detail_content_top ${authUser?.role != AdminRole.OPERATION ? 'm-t-10' : 'm-t-24'}`}>
              {renderStatusItem}
              <div className="item-detail_content_top_button-wrap">
                {handleViewButton(statusItem || '') !== '' ? (
                  <button
                    className="item-detail_content_top_button-wrap_shipping-request"
                    onClick={() => {
                      handleActionButton(statusItem || '');
                    }}
                  >
                    {intl.formatMessage({ id: `item.detail.${handleViewButton(statusItem || '')}` })}
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="item-detail_content_info-product">
              <h6 className="item-detail_content_info-product_title">
                {intl.formatMessage({ id: 'item.detail.productInformation' })}
              </h6>
              <div className="item-detail_content_info-product_table">
                <div className="item-detail_content_info-product_table_left">
                  <div className="item-detail_content_info-product_table_left_row">
                    <div className="item-detail_content_info-product_table_left_row_left">
                      <p>{intl.formatMessage({ id: 'item.detail.bayPackControlNumber' })}</p>
                      <span>:</span>
                    </div>
                    <div className="item-detail_content_info-product_table_left_row_right">
                      {itemDetail?.internalSku}
                      <span onClick={handlePrintSKU}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path
                            d="M16.353 12H17C17.943 12 18.414 12 18.707 11.707C19 11.414 19 10.943 19 10V9C19 7.114 19 6.172 18.414 5.586C17.828 5 16.886 5 15 5H5C3.114 5 2.172 5 1.586 5.586C1 6.172 1 7.114 1 9V11C1 11.471 1 11.707 1.146 11.854C1.293 12 1.53 12 2 12H3.647"
                            stroke="#514B4D"
                            strokeWidth="1.5"
                          />
                          <path
                            d="M16 5V3.88C16 2.872 16 2.368 15.804 1.983C15.6314 1.64411 15.3559 1.36861 15.017 1.196C14.632 1 14.128 1 13.12 1H6.88C5.872 1 5.368 1 4.983 1.196C4.64411 1.36861 4.36861 1.64411 4.196 1.983C4 2.368 4 2.872 4 3.88V5M4 18.306V10C4 9.057 4 8.586 4.293 8.293C4.586 8 5.057 8 6 8H14C14.943 8 15.414 8 15.707 8.293C16 8.586 16 9.057 16 10V18.306C16 18.623 16 18.781 15.896 18.856C15.792 18.931 15.642 18.881 15.342 18.781L13.158 18.053C13.08 18.027 13.041 18.013 13 18.013C12.96 18.013 12.92 18.027 12.842 18.053L10.158 18.947C10.08 18.973 10.041 18.987 10 18.987C9.96 18.987 9.92 18.973 9.842 18.947L7.158 18.053C7.08 18.027 7.041 18.013 7 18.013C6.96 18.013 6.92 18.027 6.842 18.053L4.658 18.781C4.358 18.881 4.208 18.931 4.104 18.856C4 18.781 4 18.623 4 18.306Z"
                            stroke="#514B4D"
                            strokeWidth="1.5"
                          />
                          <path d="M8 12H11M8 15H12.5" stroke="#514B4D" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div className="item-detail_content_info-product_table_left_row">
                    <div className="item-detail_content_info-product_table_left_row_left">
                      <p>{intl.formatMessage({ id: 'item.detail.customerSKU' })}</p>
                      <span>:</span>
                    </div>
                    <div className="item-detail_content_info-product_table_left_row_right">{itemDetail?.itemSku}</div>
                  </div>
                  <div className="item-detail_content_info-product_table_left_row">
                    <div className="item-detail_content_info-product_table_left_row_left">
                      <p>{intl.formatMessage({ id: 'item.detail.productName' })}</p>
                      <span>:</span>
                    </div>
                    <div className="item-detail_content_info-product_table_left_row_right">{itemDetail?.itemName}</div>
                  </div>
                </div>
                <div className="item-detail_content_info-product_table_right">
                  <div className="item-detail_content_info-product_table_left_row">
                    <div className="item-detail_content_info-product_table_right_row_left">
                      <p>{intl.formatMessage({ id: 'item.detail.trackingNumber' })}</p>
                      <span>:</span>
                    </div>
                    <div className="item-detail_content_info-product_table_right_row_right">
                      <span className="item-detail__tracking-number">
                        {itemDetail?.storageRequest?.shippoShippingLabel?.trackingNumber}
                      </span>
                    </div>
                  </div>
                  <div className="item-detail_content_info-product_table_left_row">
                    <div className="item-detail_content_info-product_table_right_row_left">
                      <p>{intl.formatMessage({ id: 'item.detail.storageLocationCode' })}</p>
                      <span>:</span>
                    </div>
                    <div className="item-detail_content_info-product_table_right_row_right">
                      {itemDetail?.storageItemRack?.rack?.code}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <UploadMedia
              statusUploadFiles={statusUploadFiles}
              formUploadByStatus={formUploadByStatus}
              itemDetail={itemDetail}
              renderTakePhotoRequest={renderTakePhotoRequest}
              previewSender={previewSender}
              renderListPhoto={renderListPhoto}
              renderListVideo={renderListVideo}
              visibleSender={visibleSender}
              setPreviewSender={setPreviewSender}
              setStatusUploadFiles={setStatusUploadFiles}
              status={statusItem}
              setIsLoadingUpload={setIsLoadingUpload}
            />

            <div className="item-detail_content_manager-comment">
              <h6 className="item-detail_content_manager-comment_title">
                {intl.formatMessage({ id: 'item.detail.ourComment' })}
              </h6>
              <TextArea
                rows={ctrlSize ? 1 : 5}
                className={`${ctrlSize ? 'item-detail__text-area-container' : ''}`}
                autoSize={{ minRows: 1, maxRows: ctrlSize ? 1 : 5 }}
                disabled={isDisableInput}
                value={itemDetailStorageVol?.staffComment}
                onChange={(e) => {
                  const value: string = e.target.value;
                  setItemDetailStorageVol({
                    ...itemDetailStorageVol,
                    staffComment: value,
                  });
                }}
                placeholder={
                  isDisableInput
                    ? ''
                    : intl.formatMessage({ id: 'item.detail.ifYouHaveAnythingToSayPleaseWriteItHere' })
                }
              />
            </div>
            <div className="item-detail_content_param">
              <div className="item-detail_content_param_size">
                <h6 className="item-detail_content_param_size_title">
                  {intl.formatMessage({ id: 'item.detail.sizeDeclaredAtTimeOfRequest' })}
                </h6>
                <div className="item-detail_content_param_size_list">
                  <div className="item-detail_content_param_size_list_item">
                    <label htmlFor="">{intl.formatMessage({ id: 'item.detail.length' })}</label>
                    <input type="text" value={itemDetail?.storageRequest?.reportLength} disabled />
                  </div>
                  <div className="item-detail_content_param_size_list_item">
                    <label htmlFor="">{intl.formatMessage({ id: 'item.detail.width' })}</label>
                    <input type="text" value={itemDetail?.storageRequest?.reportWidth} disabled />
                  </div>
                  <div className="item-detail_content_param_size_list_item">
                    <label htmlFor="">{intl.formatMessage({ id: 'item.detail.height' })}</label>
                    <input type="text" value={itemDetail?.storageRequest?.reportHeight} disabled />
                  </div>
                </div>
              </div>
              <div className="item-detail_content_param_weight">
                <h6 className="item-detail_content_param_weight_title">
                  {intl.formatMessage({ id: 'item.detail.packingWeight' })}
                </h6>
                <div className="item-detail_content_param_weight_item">
                  <input type="text" value={itemDetail?.storageRequest?.reportWeight} disabled />
                </div>
              </div>
              <div className="item-detail_content_param_size">
                <h6 className="item-detail_content_param_size_title">
                  {intl.formatMessage({ id: 'item.detail.arrivalSize' })}
                  <span className="item-detail__require">*</span>
                </h6>
                <div className="item-detail_content_param_size_list">
                  <div className="item-detail_content_param_size_list_item">
                    <label htmlFor="">
                      <label htmlFor="">{intl.formatMessage({ id: 'item.detail.length' })}</label>
                    </label>
                    <input
                      type="number"
                      value={itemDetailRealVol.realLength}
                      disabled={isDisableInput}
                      onChange={(e) => {
                        const value = e.target.value;
                        setItemDetailRealVol({
                          ...itemDetailRealVol,
                          realLength: value ? +value : undefined,
                        });
                      }}
                    />
                  </div>
                  <div className="item-detail_content_param_size_list_item">
                    <label htmlFor="">{intl.formatMessage({ id: 'item.detail.width' })}</label>
                    <input
                      type="number"
                      value={itemDetailRealVol.realWidth}
                      disabled={isDisableInput}
                      onChange={(e) => {
                        const value = e.target.value;
                        setItemDetailRealVol({
                          ...itemDetailRealVol,
                          realWidth: value ? +value : undefined,
                        });
                      }}
                    />
                  </div>
                  <div className="item-detail_content_param_size_list_item">
                    <label htmlFor="">{intl.formatMessage({ id: 'item.detail.height' })}</label>
                    <input
                      type="number"
                      value={itemDetailRealVol.realHeight}
                      disabled={isDisableInput}
                      onChange={(e) => {
                        const value = e.target.value;
                        setItemDetailRealVol({
                          ...itemDetailRealVol,
                          realHeight: value ? +value : undefined,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="item-detail_content_param_weight-container">
                <div className="item-detail_content_param_weight">
                  <h6 className="item-detail_content_param_weight_title">
                    {intl.formatMessage({ id: 'item.detail.arrivalWeight' })}
                  </h6>
                  <div className="item-detail_content_param_weight_item">
                    <input
                      type="number"
                      value={itemDetailRealVol.realWeight}
                      disabled={isDisableInput}
                      onChange={(e) => {
                        const value = e.target.value;
                        setItemDetailRealVol({
                          ...itemDetailRealVol,
                          realWeight: value ? +value : undefined,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="item-detail_content_param_size">
                <h6 className="item-detail_content_param_size_title">
                  {intl.formatMessage({ id: 'item.detail.storageSize' })}
                  <span className="item-detail__require">*</span>
                </h6>
                <div className="item-detail_content_param_size_list">
                  <div className="item-detail_content_param_size_list_item">
                    <label htmlFor="">
                      <label htmlFor="">{intl.formatMessage({ id: 'item.detail.length' })}</label>
                    </label>
                    <input
                      type="number"
                      value={itemDetailStorageVol.storageLength}
                      disabled={isDisableInput}
                      onChange={(e) => {
                        const value = e.target.value;
                        setItemDetailStorageVol({
                          ...itemDetailStorageVol,
                          storageLength: value ? +value : undefined,
                        });
                      }}
                    />
                  </div>
                  <div className="item-detail_content_param_size_list_item">
                    <label htmlFor="">{intl.formatMessage({ id: 'item.detail.width' })}</label>
                    <input
                      type="number"
                      value={itemDetailStorageVol.storageWidth}
                      disabled={isDisableInput}
                      onChange={(e) => {
                        const value = e.target.value;
                        setItemDetailStorageVol({
                          ...itemDetailStorageVol,
                          storageWidth: value ? +value : undefined,
                        });
                      }}
                    />
                  </div>
                  <div className="item-detail_content_param_size_list_item">
                    <label htmlFor="">{intl.formatMessage({ id: 'item.detail.height' })}</label>
                    <input
                      type="number"
                      value={itemDetailStorageVol.storageHeight}
                      disabled={isDisableInput}
                      onChange={(e) => {
                        const value = e.target.value;
                        setItemDetailStorageVol({
                          ...itemDetailStorageVol,
                          storageHeight: value ? +value : undefined,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="item-detail_content_param_weight">
                <h6 className="item-detail_content_param_weight_title">
                  {intl.formatMessage({ id: 'item.detail.storageWeight' })}
                </h6>
                <div className="item-detail_content_param_weight_item">
                  <input
                    type="number"
                    value={itemDetailStorageVol.storageWeight}
                    disabled={isDisableInput}
                    onChange={(e) => {
                      const value = e.target.value;
                      setItemDetailStorageVol({
                        ...itemDetailStorageVol,
                        storageWeight: value ? +value : undefined,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="item-detail_right">
            <div className="item-detail_feedback">
              <div className="item-detail_feedback_title">
                <h6>{intl.formatMessage({ id: 'item.detail.note.title' })}</h6>
                <button onClick={handleOpenRegisterNote}>{intl.formatMessage({ id: 'common.add' })}</button>
              </div>
              <div className="item-detail_feedback_content">{renderListNote}</div>
            </div>
            <ChatItem />
          </div>
        </div>
        <div className={`item-detail_cancel-modal ${activeModalCancel ? 'open' : ''}`}>
          <div className="item-detail_cancel-modal_overlay" onClick={handleCloseModalCancel}></div>
          <div className="item-detail_cancel-modal_body">
            <div className="item-detail_cancel-modal_body_text">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <path
                  d="M12.5 16.462C12.6747 16.462 12.8207 16.403 12.938 16.285C13.056 16.167 13.115 16.0207 13.115 15.846C13.115 15.672 13.056 15.526 12.938 15.408C12.8207 15.29 12.6747 15.231 12.5 15.231C12.3253 15.231 12.1793 15.29 12.062 15.408C11.9447 15.526 11.8857 15.672 11.885 15.846C11.8843 16.02 11.9433 16.1663 12.062 16.285C12.1793 16.403 12.3253 16.462 12.5 16.462ZM12 13.154H13V7.154H12V13.154ZM12.503 21C11.259 21 10.089 20.764 8.993 20.292C7.89767 19.8193 6.94467 19.178 6.134 18.368C5.32333 17.558 4.68167 16.606 4.209 15.512C3.73633 14.418 3.5 13.2483 3.5 12.003C3.5 10.7577 3.736 9.58767 4.208 8.493C4.68 7.39833 5.32133 6.44533 6.132 5.634C6.94267 4.82267 7.89467 4.181 8.988 3.709C10.0813 3.237 11.251 3.00067 12.497 3C13.743 2.99933 14.913 3.23533 16.007 3.708C17.101 4.18067 18.054 4.822 18.866 5.632C19.678 6.442 20.3197 7.394 20.791 8.488C21.2623 9.582 21.4987 10.7517 21.5 11.997C21.5013 13.2423 21.2653 14.4123 20.792 15.507C20.3187 16.6017 19.6773 17.5547 18.868 18.366C18.0587 19.1773 17.1067 19.819 16.012 20.291C14.9173 20.763 13.7477 20.9993 12.503 21Z"
                  fill="#F43258"
                />
              </svg>
              <p>依頼をキャンセルしますか？</p>
            </div>
            <div className="item-detail_cancel-modal_body_button">
              <ButtonCancel value="いいえ" onClick={handleCloseModalCancel} />
              <ButtonAgree value="はい" />
            </div>
          </div>
        </div>
        {activeAddPhoto.isShow && (
          <TakePhotoModal
            activeAddPhoto={activeAddPhoto}
            handleCloseAddPhoto={handleCloseAddPhoto}
            renderTextStatusPhotoRequest={renderTextStatusPhotoRequest}
            setActiveAddPhoto={setActiveAddPhoto}
          />
        )}
        {activeAddPhoto.isShow && (
          <TakePhotoModal
            activeAddPhoto={activeAddPhoto}
            handleCloseAddPhoto={handleCloseAddPhoto}
            renderTextStatusPhotoRequest={renderTextStatusPhotoRequest}
            setActiveAddPhoto={setActiveAddPhoto}
          />
        )}

        <div className={`item-detail_register-note ${activeRegisterNote ? 'open' : ''}`}>
          <div className="item-detail_register-note_overlay" onClick={handleCloseRegisterNote}></div>
          <div className="item-detail_register-note_body">
            <h6 className="item-detail_register-note_body_title">
              {intl.formatMessage({ id: 'item.detail.note.title' })}
            </h6>
            <div className="item-detail_register-note_body_content">
              <FormWrap form={form}>
                <label htmlFor="">
                  {intl.formatMessage({ id: 'item.detail.note.content' })}
                  <span>*</span>
                </label>
                <Form.Item name="message" required rules={[{ required: true, message: '' }]}>
                  <CustomInputArea placeholder={intl.formatMessage({ id: 'chat-item.send' })} />
                </Form.Item>
              </FormWrap>

              {/* <textarea name="" id="" placeholder={'備考登録を入力'}></textarea> */}
            </div>
            <div className="item-detail_register-note_body_button-wrap">
              <ButtonCancel value={intl.formatMessage({ id: 'common.cancel' })} onClick={handleCloseRegisterNote} />
              <ButtonAgree value={intl.formatMessage({ id: 'common.registration' })} onClick={handleRegisterNote} />
            </div>
          </div>
        </div>
        <div className={`item-detail_note-item ${activeNoteItem ? 'open' : ''}`}>
          <div className="item-detail_note-item_overlay" onClick={handleCloseNoteItem}></div>
          <div className="item-detail_note-item_body">
            <h6 className="item-detail_note-item_body_title">備考詳細</h6>
            <div className="item-detail_note-item_body_info">
              <div className="item-detail_note-item_body_info_name">
                <img
                  src={Helper.getSourceFile(noteDetail?.staff?.avatar?.source || noteDetail?.staff?.avatar?.preview)}
                  alt=""
                />
                <p className="font-size-14">{noteDetail?.staff?.name ?? ''}</p>
              </div>
              <div className="item-detail_note-item_body_info_time font-size-14">
                登録日時：{moment(noteDetail?.createdAt).format(TIME_FORMAT.MESSAGE_TIME)}
              </div>
            </div>
            <div className="item-detail_note-item_body_content">
              <label htmlFor="">{intl.formatMessage({ id: 'item.detail.note.content' })}</label>
              <textarea name="" id="" value={noteDetail?.content || ''} disabled></textarea>
            </div>
            <div className="item-detail_note-item_body_button-wrap">
              <ButtonCancel value="閉じる" onClick={handleCloseNoteItem} />
            </div>
          </div>
        </div>
        <Modal
          title=""
          closable={false}
          open={isOpen}
          className="item-detail__inputed-modal-container"
          centered={true}
          onCancel={() => setIsOpen(false)}
          closeIcon={<SvgCloseModalIcon />}
          footer={
            <div className="item-detail__inputed-button-container">
              <CustomCommonButton
                isCancelButton={true}
                buttonProps={{
                  className: 'item-detail__inputed-button-action',
                  onClick: () => setIsOpen(false),
                  block: true,
                }}
                content={intl.formatMessage({ id: 'common.cancel' })}
              />
              <CustomCommonButton
                isCancelButton={true}
                buttonProps={{
                  className: 'item-detail__inputed-button-action',
                  type: 'primary',
                  onClick: () => formUpdateItem.submit(),
                  block: true,
                  loading: updatePriceMutation.isPending || updatePriceMutation.isPending,
                }}
                content={intl.formatMessage({ id: 'common.submit' })}
              />
            </div>
          }
        >
          <FormWrap form={formUpdateItem} onFinish={onFinishUpdatePrice} onFinishFailed={onFinishFailedUpdatePrice}>
            <Form.Item
              name={'price'}
              className="item-detail__inputed-input-price"
              label={
                <p className="item-detail__inputed-input-label">
                  {intl.formatMessage({ id: 'item.detail.disposalAmount' })}
                  <span>*</span>
                </p>
              }
              required
              rules={[{ required: true, message: '' }]}
            >
              <Input prefix={<SvgDolarIcon />} type="number" />
            </Form.Item>
          </FormWrap>
        </Modal>
        <Modal
          title=""
          closable={false}
          open={isOpenConfirm}
          className="item-detail__confirm-disposal"
          centered={true}
          onCancel={() => setIsOpenConfirm(false)}
          closeIcon={<SvgCloseModalIcon />}
          footer={null}
        >
          <div className="item-detail__confirm-disposal-title">
            <SvgWarningIcon />
            <p>{intl.formatMessage({ id: 'item.detail.confirmDisposal' })}</p>
          </div>
          <div className="item-detail__inputed-button-container">
            <CustomCommonButton
              isCancelButton={true}
              buttonProps={{
                className: 'item-detail__inputed-button-action',
                onClick: () => setIsOpenConfirm(false),
                block: true,
              }}
              content={intl.formatMessage({ id: 'common.cancel' })}
            />
            <CustomCommonButton
              isCancelButton={true}
              buttonProps={{
                className: 'item-detail__inputed-button-action',
                type: 'primary',
                onClick: () => confirmDisposal(),
                block: true,
                loading: completedDisposalMutation.isPending || completedDisposalMutation.isPending,
              }}
              content={intl.formatMessage({ id: 'common.yes' })}
            />
          </div>
        </Modal>
        <Modal
          title=""
          closable={false}
          open={isOpenCheck}
          className="item-detail__percent-diff"
          centered={true}
          onCancel={() => setIsOpenCheck(false)}
          closeIcon={<SvgCloseModalIcon />}
          footer={null}
        >
          <div className="item-detail__percent-diff-title">
            <SvgWarningIcon />
            <p>{intl.formatMessage({ id: 'item.detail.percentDiff' })}</p>
          </div>
          <div className="item-detail__inputed-button-container">
            <CustomCommonButton
              isCancelButton={true}
              buttonProps={{
                className: 'item-detail__inputed-button-action',
                onClick: () => setIsOpenCheck(false),
                block: true,
              }}
              content={intl.formatMessage({ id: 'common.cancel' })}
            />
            <CustomCommonButton
              isCancelButton={true}
              buttonProps={{
                className: 'item-detail__inputed-button-action',
                type: 'primary',
                onClick: () => submitSize(),
                block: true,
                disabled: uploadRealVolume.isPending || uploadStorageVolume.isPending,
                loading: uploadRealVolume.isPending || uploadStorageVolume.isPending,
              }}
              content={intl.formatMessage({ id: 'common.yes' })}
            />
          </div>
        </Modal>
      </div>
    </Spin>
  );
};
export default ItemDetail;
