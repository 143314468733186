const ADMIN = '';

const ACTION = {
  CREATE: 'create',
  DETAIL: 'detail',
};

export const ADMIN_ROUTE_NAME = {
  DASHBOARD: '',
  SIGNIN: 'signin',
  PROFILE: 'profile',
  STAFF: 'staff-management',
  NOTIFY_MANAGEMENT: 'notify-management',
  ITEM_MANAGEMENT: 'item-management',
  ITEM_MANAGEMENT_DETAIL: `${ACTION.DETAIL}/:id`,
  REQUEST_MANAGEMENT: 'request-management',
  REQUEST_MANAGEMENT_DETAIL: `${ACTION.DETAIL}/:id`,
  COUPON_MANAGEMENT: 'coupon-management',
  ANALYSIS_MANAGEMENT: 'analysis-management',
  CUSTOMER_ORDER: 'customer-order-management',
  CUSTOMER_INFORMATION: 'customer-information-management',
  PAYMENT_SETTING: 'payment-setting',
  CHAT_SUPPORT: 'chat-support',
  STORAGE: 'storage',
  WAREHOUSE_CHECK: 'warehouse-check',
  RACK_MANAGEMENT: 'rack-management',
  SHIPMENT_MANAGEMENT: 'shipment-management',
  // USER_MANAGEMENT: 'user-management',
  // USER_MANAGEMENT_DETAIL: `${ACTION.DETAIL}/:id`,
  SALE_MANAGEMENT: 'sale-management',
  SETTING: 'setting-management',

  CREATE: 'create',
  DETAIL: 'detail/:id',

  //   plan
  PLAN_SETTING: 'plan-setting',
  SELECT_PLAN: 'select-plan-setting',
  DETAIL_PLAN: 'detail-plan/:id',
};

export const ADMIN_ROUTE_PATH = {
  DASHBOARD: `/${ADMIN_ROUTE_NAME.DASHBOARD}`,
  SIGNIN: `${ADMIN}/${ADMIN_ROUTE_NAME.SIGNIN}`,
  PROFILE: `${ADMIN}/${ADMIN_ROUTE_NAME.PROFILE}`,
  SETTING: `${ADMIN}/${ADMIN_ROUTE_NAME.SETTING}`,
  NOTIFY_MANAGEMENT: `${ADMIN}/${ADMIN_ROUTE_NAME.NOTIFY_MANAGEMENT}`,
  ITEM_MANAGEMENT: `${ADMIN}/${ADMIN_ROUTE_NAME.ITEM_MANAGEMENT}`,
  ITEM_MANAGEMENT_DETAIL: `${ADMIN}/${ADMIN_ROUTE_NAME.ITEM_MANAGEMENT}/${ACTION.DETAIL}`,
  REQUEST_MANAGEMENT: `${ADMIN}/${ADMIN_ROUTE_NAME.REQUEST_MANAGEMENT}`,
  STAFF_CREATE: `${ADMIN}/${ADMIN_ROUTE_NAME.STAFF}/${ACTION.CREATE}`,
  STAFF_DETAIL: `${ADMIN}/${ADMIN_ROUTE_NAME.STAFF}/${ACTION.DETAIL}/:id`,

  CUSTOMER_ORDER_CREATE: `${ADMIN}/${ADMIN_ROUTE_NAME.CUSTOMER_ORDER}/${ACTION.CREATE}`,
  CUSTOMER_ORDER_DETAIL: `${ADMIN}/${ADMIN_ROUTE_NAME.CUSTOMER_ORDER}/${ACTION.DETAIL}/:id`,

  PAYMENT_SETTING_CREATE: `${ADMIN}/${ADMIN_ROUTE_NAME.PAYMENT_SETTING}/${ACTION.CREATE}`,
  PAYMENT_SETTING_DETAIL: `${ADMIN}/${ADMIN_ROUTE_NAME.PAYMENT_SETTING}/${ACTION.DETAIL}`,

  PLAN_SETTING: `${ADMIN}/${ADMIN_ROUTE_NAME.PAYMENT_SETTING}/${ADMIN_ROUTE_NAME.DETAIL}/${ADMIN_ROUTE_NAME.PLAN_SETTING}`,
  SELECT_PLAN: `${ADMIN}/${ADMIN_ROUTE_NAME.PAYMENT_SETTING}/${ADMIN_ROUTE_NAME.DETAIL}/${ADMIN_ROUTE_NAME.PLAN_SETTING}/${ADMIN_ROUTE_NAME.DETAIL}`,
};

export const STAFF_PERMISSIONS = {
  SuperAdmin: 'SuperAdmin',
  Authenticated: 'Authenticated',
  ChangePassword: 'ChangePassword',
  ItemManagement: 'ItemManagement',
  Profile: 'Profile',
  RackManagement: 'RackManagement',
  RequestManagement: 'RequestManagement',
  ShipmentManagement: 'ShipmentManagement',
  Storage: 'Storage',
  WarehouserCheck: 'WarehouserCheck',
  ChatSupport: 'ChatSupport',
  Admin: 'Admin',
};
